import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchDocumentData } from "../utils/fetchDocumentData";

const DocumentSectionsContext = createContext();

export const DocumentSectionsProvider = ({ children, patientId, docId }) => {
  const [sections, setSections] = useState([]);
  const [documentData, setDocumentData] = useState(null);
  const [documentHistory, setDocumentHistory] = useState(null);

  useEffect(() => {
    if (patientId && docId) {
      fetchDocumentData(patientId, docId, null, setDocumentData, null, setDocumentHistory)
        .then((data) => {
          if (data && data.sections) {
            console.log("Document Sections:", data.sections);
            setSections(data.sections);
          }
        })
        .catch((error) => console.error("Error fetching document data:", error));
    }
  }, [patientId, docId]);

  return (
    <DocumentSectionsContext.Provider value={{ sections, documentData, documentHistory }}>
      {children}
    </DocumentSectionsContext.Provider>
  );
};

export const useDocumentSections = () => {
  return useContext(DocumentSectionsContext);
};