import ReactDOMServer from "react-dom/server";
import { processHistory, mostRecentTherapyHistory } from "./historyProcessing";

export const capitalizeAndFormat = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function formatListItem(key, value) {
  return (
    <li>
      <strong>{capitalizeAndFormat(key)}:</strong> {value}
    </li>
  );
}

export const generateSectionExport = (section, documentData, dataHistory) => {
  switch (section.type) {
        case "StartEndTime":
            const startTimeHtml = documentData.startTime
                ? `<p style="margin: 0; padding: 0;"><strong>Start Time:</strong> ${documentData.startTime}</p>`
                : "";
            const endTimeHtml = documentData.endTime
                ? `<p style="margin: 0; padding: 0;"><strong>End Time:</strong> ${documentData.endTime}</p>`
                : "";
            const dataHtml = startTimeHtml && endTimeHtml
                ? `<div class="export-section">${startTimeHtml}${endTimeHtml}</div>`
                : `${startTimeHtml}${endTimeHtml}`;
            return {
                startEndTime: {
                    data: dataHtml,
                },
            };
    
        case "TherapyTiming":
                  const mostRecent = (mostRecentTherapyHistory(dataHistory))
                    const therapyTypeHtml = documentData.therapyType
                        ? `<p style="margin: 0; padding: 0;"><strong>Therapy Type:</strong> ${documentData.therapyType}</p>`
                        : mostRecent.therapyType ? `<p style="margin: 0; padding: 0;"><strong>Therapy Type:</strong> ${mostRecent.therapyType}</p>` : "";
                    const therapyMinutesHtml = documentData.therapyMinutes
                        ? `<p style="margin: 0; padding: 0;"><strong>Therapy Minutes:</strong> ${documentData.therapyMinutes}</p>`
                        : "";
                    const therapyDataHtml = therapyTypeHtml || therapyMinutesHtml
                        ? `<div class="export-section-short">${therapyTypeHtml}${therapyMinutesHtml}</div>`
                        : "";
                    return {
                        therapyTiming: {
                            data: therapyDataHtml,
                        },
                    };
    case "EditableTextSection":
      return {
        [section.dataField]: {
          data: documentData[section.dataField],
          title: section.title,
        },
      };

    case "EditableDataSection": // basic two column sections
      const dataItems = documentData[section.data];
      const historyData = processHistory(dataHistory, section.data);
      let formattedData = "<ul>";

      section.fields.forEach((field) => {
        let value =
          dataItems && dataItems[field]
            ? dataItems[field]
            : historyData && historyData[field] && historyData[field][0].value
            ? historyData[field][0].value
            : section.defaultExport;

        if (value === undefined || value === null) {
          value = "";
        }

        if (section.exportType !== "hideEmpty" || value !== "") {
          formattedData += ReactDOMServer.renderToString(
            formatListItem(field, value)
          );
        }
      });

      formattedData += "</ul>";
      return {
        [section.data]: {
          data: formattedData,
          title: section.title,
        },
      };

    case "EditableTableSection": // multi-column sections
      const tableData = documentData[section.subData];
      if (!tableData || tableData.length === 0) {
        return {};
      }

      let formattedTableData = "<ul>";
      let currentItems = "";
      let pastItems = "";

      tableData.forEach((item) => {
        if (item.isDeleted || item.isHidden) {
          return;
        }

        if (item.hasOwnProperty("patient_use") && item.patient_use === false) {
          return null;
        }

        if (section.exportType === "currentOnly" && !item.current) {
          return;
        }

        let listItem = "<li>";
        let firstColumn = false;
        Object.keys(section.fields).forEach((fieldKey, index, array) => {
          const field = section.fields[fieldKey];
          if (item[fieldKey] && field.type !== "check") {
            if (!firstColumn) {
              listItem += `<strong>${capitalizeAndFormat(
                item[fieldKey]
              )}:</strong> `;
              firstColumn = true;
            } else {
              listItem += `${item[fieldKey]}`;
              if (index < array.length - 1) {
                listItem += ", ";
              }
            }
          }
        });
        listItem += "</li>";

        if (section.exportType === "splitCurrentPast") {
          if (item.current) {
            currentItems += listItem;
          } else {
            pastItems += listItem;
          }
        } else {
          formattedTableData += listItem;
        }
      });

      if (section.exportType === "splitCurrentPast") {
        formattedTableData += "<h4>Current:</h4>";
        formattedTableData += currentItems;
        formattedTableData += "<br/><h4>Past:</h4>";
        formattedTableData += pastItems;
      }

      formattedTableData += "</ul>";

      return {
        [section.subData]: {
          data: formattedTableData,
          title: section.title,
        },
      };

    case "Confirmation":
      const confirmationMessage =
        documentData[section.dataField]?.exportMessage || "";
      return {
        [section.dataField]: {
          data: confirmationMessage,
          title: "",
        },
      };

    default:
      return null;
  }
};
