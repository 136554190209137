import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { addData, updateData } from './utils/firestoreService';
import { initializeWebSocket } from './utils/webSocket';
import ProgressBar, { parseProgressFromMessage } from './utils/progressBar';
import { fetchPatientData } from './utils/fetchPatientData';
import { historyFields } from './globals';
import { fetchDocumentTypes } from './utils/fetchDocumentTypes';

const NewText = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { patientId } = location.state || {};
    const [patientData, setPatientData] = useState({});
    const [patientDocuments, setPatientDocuments] = useState([]);
    const [wsMessages, setWsMessages] = useState([]);
    const [pastedText, setPastedText] = useState('');
    const [buttonText, setButtonText] = useState('Upload Text');
    const [clientId, setClientId] = useState(null);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [eta, setEta] = useState(null);
    const [uploadStartTime, setUploadStartTime] = useState(null);

    const hasFetchedDataRef = useRef(false);
    const progressBarRef = useRef(null);

    useEffect(() => {
        if (hasFetchedDataRef.current) return;

        if (!patientId) {
            navigate('/patient', { replace: true });
            return;
        }

        fetchPatientData(patientId, setPatientData, setPatientDocuments);
        hasFetchedDataRef.current = true;
    }, [patientId, navigate]);

    useEffect(() => {
        const { socket, sendMessage } = initializeWebSocket(setWsMessages, setClientId);
        sendMessage('connected for NewText');
        return () => {
            socket.close();
        };
    }, []);

    useEffect(() => {
        const defaultId = 0;
        fetchDocumentTypes(setDocumentTypes, setSelectedDocumentType, setSelectedDocumentTypeId, setSelectedCategoryId, setEta, defaultId);
    }, []);

    useEffect(() => {
        progressBarRef.current = new ProgressBar('progress-container', eta);
    }, [eta]);

    useEffect(() => {
        if (wsMessages.length > 0) {
            const latestMessage = wsMessages[wsMessages.length - 1];
            try {
                const jsonMessage = JSON.parse(latestMessage);
                const progressData = parseProgressFromMessage(jsonMessage);
                if (progressData) {
                    progressBarRef.current.update(progressData);
                }
            } catch (error) {
                return;
            }
        }
    }, [wsMessages]);

    useEffect(() => {
        if (uploadStartTime) {
            const timer = setInterval(() => {
                const elapsedTime = Math.floor((Date.now() - uploadStartTime) / 1000);
                progressBarRef.current.update({ time: elapsedTime });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [uploadStartTime]);

    const handleTextUpload = async () => {
        if (!pastedText) {
            return;
        }
    
        setButtonText('Uploading...');
        const startTime = Date.now();
        setUploadStartTime(startTime);
    
        const patientHistory = JSON.parse(JSON.stringify(patientDocuments));
        Object.keys(patientHistory).forEach(key => {
            const document = patientHistory[key];
            Object.keys(document).forEach(field => {
                if (!historyFields.includes(field)) {
                    delete document[field];
                }
            });
        });

        // Create an initial record with status "processing"
        const initialData = {
            title: selectedDocumentType,
            description: "Text Copy / Paste",
            processed_text: pastedText,
            type: selectedDocumentType,
            typeId: selectedDocumentTypeId,
            category: selectedCategoryId,
            createdAt: new Date(startTime),
            documentDate: new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }),
            startTime: new Date(startTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
            status: "processing"
        };

        let docId;
        try {
            docId = await addData(`patients/${patientId}/documents`, initialData);
        } catch (error) {
            console.error("Error creating initial record:", error);
            setButtonText('Upload Text');
            return;
        }
    
        try {
            const response = await fetch('/processText', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ text: pastedText, clientId, docTypeId: selectedDocumentTypeId, patientData, patientHistory })
            });
    
            if (response.ok) {
                const data = await response.json();
                // Update the record with completed data and change status to "completed"
                const updatedData = {
                    ...data,
                    status: "completed",
                    endTime: new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
                    modifiedAt: new Date()
                };
                await updateData(`patients/${patientId}/documents`, docId, updatedData);
                navigate('/document', { state: { patientId, docId } });
            }
        } catch (error) {
            console.error('Error processing text:', error);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleDocumentTypeChange = (e) => {
        const selectedType = documentTypes.find(type => type.name === e.target.value);
        fetchDocumentTypes(setDocumentTypes, setSelectedDocumentType, setSelectedDocumentTypeId, setSelectedCategoryId, setEta, selectedType.id - 1);
    };

    return (
        <div className='page-container'>
            {patientData && (
                <div className='header-container'>
                    <button onClick={handleBack}>&larr;</button>
                    <div className='page-title'>
                        <strong>Uploading Transcript Text:</strong> {patientData.firstName} {patientData.lastName}
                    </div>
                </div>
            )}
            <div className='standard-container'>
                <p>Please paste text from a transcript in the box below.</p>
                <p className="programmer-note"></p>
                <textarea
                    value={pastedText}
                    className={'transcript-box'}
                    onChange={(e) => setPastedText(e.target.value)}
                    rows="10"
                    cols="90"
                ></textarea>
                <br /> <br />
                {Array.isArray(documentTypes) && documentTypes.length > 0 && (
                    <div className="dropdown-container justify-right">
                        <label htmlFor="documentType" className="dropdown-label">Select Document Type:</label>
                        <select
                            id="documentType"
                            className="dropdown-select"
                            value={selectedDocumentType}
                            onChange={handleDocumentTypeChange}
                        >
                            {documentTypes.map((type) => (
                                <option key={type.id} value={type.name}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <br /> <br />
                <div className="upload-container">
                    <div id="progress-container"></div>
                    <button className="button-add button-larger" onClick={handleTextUpload}>{buttonText}</button>
                </div>
            </div>
        </div>
    );
};

export default NewText;