
//standard full history processor, used for export
export const processHistory = (dataHistory, originalFieldKey) => {
    if (!dataHistory) return;

    const fullHistoryData = {};

    for (let i = dataHistory.length - 1; i >= 0; i--) {
        const record = dataHistory[i];
        const fieldData = record[originalFieldKey];
        const date = record.modifiedAt;
        for (const key in fieldData) {
            if (fieldData[key] !== "") {
                if (!fullHistoryData[key]) {
                    fullHistoryData[key] = [];
                }
                fullHistoryData[key].push({ value: fieldData[key], date: date });
            }
        }
    }

    return fullHistoryData;
};

// individual field processor with field key, used on data sections
export const processHistoryData = (history, fieldKey) => {
    let historyDataItem = '';
    let historyData = [];

    if (history && fieldKey && Array.isArray(history[fieldKey])) {
        const sortedData = history[fieldKey].sort((a, b) => {
            if (a.date.seconds !== b.date.seconds) {
                return b.date.seconds - a.date.seconds;
            }
            return b.date.nanoseconds - a.date.nanoseconds;
        });

        if (sortedData.length > 0) {
            historyDataItem = sortedData[0].value;
        }

        historyData = sortedData.map(item => ({
            value: item.value,
            date: item.date
        }));
    }

    return { historyDataItem, historyData };
};

// process history data without field key, used on tables to avoid matching items
export const processHistoryMatch = (history, getKey) => {
    let historyData = [];
    let recordMap = new Map();

    history.forEach(record => {
        Object.keys(record).forEach(key => {
            if (key !== 'modifiedAt') {
                let item = record[key];
                let primaryValue = getKey(item);
                let modifiedAt = record.modifiedAt.seconds * 1000 + Math.floor(record.modifiedAt.nanoseconds / 1000000);
                if (!recordMap.has(primaryValue) || recordMap.get(primaryValue).modifiedAt < modifiedAt) {
                    recordMap.set(primaryValue, { ...item, modifiedAt, isHistory: true });
                }
            }
        });
    });

    historyData = Array.from(recordMap.values()).map(item => {
        return item;
    });

    return historyData;
};

// process history  by section with sorting, usually for tables, currentData is one record, history is many
export const processHistorySection = (section, currentData, historyData) => {
    if (!section?.primaryField) return [{}];

    const uniqueDataMap = new Map();
    const safeCurrentData = Array.isArray(currentData) ? currentData : [];
    const safeHistoryData = Array.isArray(historyData) ? historyData : [];

    const getKey = item => item ? (item[section.primaryField] || item.id || '') : '';

    safeCurrentData.forEach(item => {
        if (item) uniqueDataMap.set(getKey(item), item);
    });

    safeHistoryData.forEach(item => {
        if (item && !uniqueDataMap.has(getKey(item))) {
            uniqueDataMap.set(getKey(item), item);
        }
    });

    let combinedData = Array.from(uniqueDataMap.values());

    switch (section.sort) {
        case "alphabetical":
            combinedData.sort((a, b) => {
                const fieldA = (a[section.primaryField] || '').toLowerCase();
                const fieldB = (b[section.primaryField] || '').toLowerCase();
                return fieldA.localeCompare(fieldB);
            });
            break;
        case "current":
            combinedData.sort((a, b) => {
                if (a.current !== b.current) return b.current ? 1 : -1;
                return (a[section.primaryField] || '').toLowerCase()
                    .localeCompare((b[section.primaryField] || '').toLowerCase());
            });
            break;
        case "custom":
            if (Array.isArray(section.sortOrder)) {
                combinedData.sort((a, b) => {
                    const sortOrder = section.sortOrder;
                    const indexA = sortOrder.indexOf(a[section.primaryField] || '');
                    const indexB = sortOrder.indexOf(b[section.primaryField] || '');
                    const maxIndex = sortOrder.length;
                    return (indexA === -1 ? maxIndex : indexA) - (indexB === -1 ? maxIndex : indexB);
                });
            }
            break;
    }

    if (!section.allowMultiples) {
        combinedData = combinedData.filter((item, index, self) => 
            index === self.findIndex(t => getKey(t) === getKey(item))
        );
    }

    return combinedData.length ? combinedData : [{}];
};

// currently used for fetching the most Recent Therapy Record
export const mostRecentTherapyHistory = (documentHistory) => {
    const therapyRecords = documentHistory.filter(
      (record) => record.therapyType
    );
    if (therapyRecords.length > 0) {
      const mostRecentRecord = therapyRecords.reduce((latest, record) => {
        return new Date(record.documentDate) > new Date(latest.documentDate)
          ? record
          : latest;
      });
      return mostRecentRecord;
    }
  }

// returns the most recent document that contains history summaries
export const mostRecentHistory = (patientDocuments) => {
  if (patientDocuments.length > 0) {
    const filteredDocuments = patientDocuments.filter(
      (doc) => doc.history_summary // only choose docs with history summary
    );
    if (filteredDocuments.length > 0) {
      const mostRecent = filteredDocuments.reduce((latest, doc) => {
        return doc.createdAt.seconds > latest.createdAt.seconds ? doc : latest;
      }, filteredDocuments[0]);
      return mostRecent;
    }
  }
  return null;
};

// returns the most recent document that contains a specific field
export const mostRecentDocument = (patientDocuments, field) => {
    if (patientDocuments.length > 0) {
        const filteredDocuments = patientDocuments.filter(
            (doc) => doc[field] 
        );
        if (filteredDocuments.length > 0) {
            const mostRecent = filteredDocuments.reduce((latest, doc) => {
                return doc.createdAt.seconds > latest.createdAt.seconds ? doc : latest;
            }, filteredDocuments[0]);
            return mostRecent;
        }
    }
    return null;
};