import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { addData, updateData, updateUserSettings, getUserSettings } from "./utils/firestoreService";
import { initializeWebSocket } from "./utils/webSocket";
import { useAuth } from "./utils/authContext";
import ProgressBar, { parseProgressFromMessage } from "./utils/progressBar";
import { fetchPatientData } from "./utils/fetchPatientData";
import { fetchDocumentTypes } from "./utils/fetchDocumentTypes";
import { recordingConfirmationMessage, historyFields, recordingDebounceTime, recordingTimeLimit } from "./globals";
import SubTabs from "./tabs/subtabs";
import { fetchAudioInputDevices, handleMicChange } from "./utils/audioUtils";
import { handleStartStreaming } from "./utils/streamingUtils";
import SettingsIcon from "@mui/icons-material/Settings";
import { mostRecentHistory } from "./utils/historyProcessing";
import { useStreaming } from "./contexts/StreamingContext";

const NewSession = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  const { patientId } = location.state || {};
  const [clientId, setClientId] = useState(null);
  const [patientData, setPatientData] = useState({});
  const [patientDocuments, setPatientDocuments] = useState([]);
  const [historySummary, setHistorySummary] = useState([]);
  const socketRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioStreamRef = useRef(null);
  const { isStreaming, setIsStreaming } = useStreaming();
  const [wsMessages, setWsMessages] = useState([]);
  const [recordingTime, setRecordingTime] = useState(0);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [beginProcessing, setBeginProcessing] = useState(false);
  const [processingFail, setProcessingFail] = useState(false);
  const [cancelProcessing, setCancelProcessing] = useState(false);
  const [isMicModalOpen, setIsMicModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [currentStatus, setCurrentStatus] = useState("Waiting");
  const [documentTypes, setDocumentTypes] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const progressBarRef = useRef(null);
  const [eta, setEta] = useState(null);
  const [uploadStartTime, setUploadStartTime] = useState(null);
  const hasFetchedDataRef = useRef(false);
  const hasFetchedAudioDevicesRef = useRef(false);

  useEffect(() => {
    if (hasFetchedDataRef.current) return;

    if (!patientId) {
      navigate("/patient", { replace: true });
      return;
    }

    fetchPatientData(patientId, setPatientData, setPatientDocuments);
    hasFetchedDataRef.current = true;

    if (!hasFetchedAudioDevicesRef.current) {
      fetchAudioInputDevices(setAudioInputDevices, setSelectedDeviceId);
      hasFetchedAudioDevicesRef.current = true;
    }
  }, [patientId, navigate]);

  useEffect(() => {
    const mostRecent = mostRecentHistory(patientDocuments);
    if (mostRecent) {
      setHistorySummary(mostRecent);
    }
  }, [patientDocuments]);

  useEffect(() => {
    const defaultId = 0;
    fetchDocumentTypes(setDocumentTypes, setSelectedDocumentType, setSelectedDocumentTypeId, setSelectedCategoryId, setEta, defaultId);
  }, []);

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const settings = await getUserSettings(userId);
        if (settings && settings.microphone) {
          setSelectedDeviceId(settings.microphone);
        }
      } catch (error) {
        console.error("Error fetching user settings:", error);
      }
    };

    if (userId) {
      fetchUserSettings();
    }
  }, [userId]);

  useEffect(() => {
    if (uploadStartTime) {
      const timer = setInterval(() => {
        const elapsedTime = Math.floor((Date.now() - uploadStartTime) / 1000);
        progressBarRef.current.update({ time: elapsedTime });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [uploadStartTime]);

  useEffect(() => {
    progressBarRef.current = new ProgressBar("progress-container", eta, isProcessing);
  }, [eta, isProcessing]);

  useEffect(() => {
    if (wsMessages.length > 0) {
      const latestMessage = wsMessages[wsMessages.length - 1];
      try {
        const jsonMessage = JSON.parse(latestMessage);
        console.log(jsonMessage);
        const progressData = parseProgressFromMessage(jsonMessage);

        if (progressData) {
          progressBarRef.current.update(progressData);
        }
      } catch (error) {
        return;
      }
    }
  }, [wsMessages]);

  useEffect(() => {
    const { socket, sendMessage } = initializeWebSocket(setWsMessages, setClientId);
    socketRef.current = socket;
    sendMessage("connected for audio");
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    setCurrentStatus(getCurrentStatus());
  }, [isStreaming, recordingTime]);

  useEffect(() => {
    let timer;
    if (isStreaming) {
      timer = setInterval(() => {
        setRecordingTime((prevTime) => {
          if (prevTime >= recordingTimeLimit) {
            handleStopStreaming("stop");
            return prevTime;
          }
          return prevTime + 1;
        });
      }, 1000);
    } else {
      setRecordingTime(0);
    }
    return () => clearInterval(timer);
  }, [isStreaming]);

  const handleBack = () => {
    if (isStreaming) {
      const confirmLeave = window.confirm("You have an ongoing recording. Are you sure you want to leave?");
      if (!confirmLeave) return;
    }
    navigate(-1);
  };

  const handleStreamingConfirm = () => {
    setIsModalOpen(true);
  };

  const handleSubTabChange = (event, newValue) => {
    setActiveSubTab(newValue);
  };

  const getCurrentStatus = () => {
    if (isStreaming) {
      return `Streaming... ${Math.floor(recordingTime / 60)}m ${recordingTime % 60}s`;
    } else {
      return "Processing...";
    }
  };

  const toggleMicModalVisibility = () => {
    setIsMicModalOpen(!isMicModalOpen);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const attemptUpdateData = debounce(async (path, documentId, data) => {
    console.log("attemptUpdateData function called");
    await updateData(path, documentId, data);
  }, recordingDebounceTime);

  const handleStopStreaming = (action = "stop") => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.disconnect();
      mediaRecorderRef.current = null;
    }

    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach((track) => track.stop());
      audioStreamRef.current = null;
    }

    if (socketRef.current && socketRef.current.connected) {
      const message = { action };
      socketRef.current.emit("action", JSON.stringify(message));
    } else {
      console.error("Socket.io is not connected");
    }

    setIsStreaming(false);

    if (action === "stop") {
      setBeginProcessing(true);
      setIsProcessing(true);
    }

    if (action === "cancel") {
      setIsProcessing(false);
      setBeginProcessing(false);
      setCancelProcessing(true);
    }
  };

  const saveData = async (patientId, documentId, processedText, data) => {
    try {
      const updatedDocData = {
        title: selectedDocumentType,
        description: "Audio Session",
        processed_text: processedText,
        type: selectedDocumentType,
        typeId: selectedDocumentTypeId,
        category: selectedCategoryId,
        documentDate: new Date().toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
        endTime: new Date().toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        }),
        modifiedAt: new Date(),
        ...data,
      };

      let docId;
      if (documentId) {
        await updateData(`patients/${patientId}/documents`, documentId, updatedDocData);
        docId = documentId;
      } else {
        docId = await addData(`patients/${patientId}/documents`, updatedDocData);
      }

      navigate("/document", { state: { patientId, docId } });
    } catch (error) {
      console.error("Error saving processed transcript:", error);
    }
  };

  const handleDocumentTypeChange = (e) => {
    const selectedType = documentTypes.find((type) => type.name === e.target.value);
    fetchDocumentTypes(setDocumentTypes, setSelectedDocumentType, setSelectedDocumentTypeId, setSelectedCategoryId, setEta, selectedType.id - 1);
  };

  return (
    <div className="page-container">
      <div className="header-container">
        <button className="back-button" onClick={handleBack}>
          &larr;
        </button>
        <div className="page-title">
          <strong>Record Session with:</strong> {patientData.firstName} {patientData.lastName}
        </div>
        <button className="back-button" onClick={toggleMicModalVisibility}>
          <SettingsIcon />
        </button>
      </div>
      <div className={`session-header-container ${isStreaming ? "session-streaming" : ""} ${isProcessing ? "session-processing" : ""}`}>
        {Array.isArray(documentTypes) && !isProcessing  && !beginProcessing && documentTypes.length > 0 && (
          <div className="session-recording-container">
            <div className="dropdown-container">
              <label htmlFor="documentType" className="dropdown-label">
                Session Type:
              </label>
              {isStreaming || isProcessing ? (
                <span className="dropdown-selected">{selectedDocumentType}</span>
              ) : (
                <select id="documentType" className="dropdown-select" value={selectedDocumentType} onChange={handleDocumentTypeChange}>
                  {documentTypes.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        )}

        {(!isProcessing && !beginProcessing) && (
          <div className="streaming-container">
            <button
              className="recording-button"
              onClick={() => {
                if (isStreaming) {
                  handleStopStreaming("stop");
                } else {
                  handleStreamingConfirm();
                }
              }}>
              {isStreaming ? "End Recording & Begin Processing" : "Begin Recording"}
            </button>
            {isStreaming && (
              <div className="streaming-controls">
                <button className="cancel-button" onClick={() => handleStopStreaming("cancel")}>
                  Cancel
                </button>
                <span className="recording-status">{currentStatus}</span>
              </div>
            )}

            {isStreaming && (
              <div className="volume-meter">
                {Array.from({ length: 10 }).map((_, index) => (
                  <div
                    key={index}
                    className="volume-bar"
                    style={{
                      display: index < volumeLevel ? "block" : "none",
                    }}></div>
                ))}
              </div>
            )}
          </div>
        )}

        <div id="progress-container" className="session-progress-container"></div>

        {processingFail && <div>Processing has failed. Please use the "Issues?" button below to report this problem.</div>}

        {beginProcessing && <div>AI Processing in progress...</div>}
      </div>

      <div className="session-header-spacing">Patient History</div>
      <div className="session-subtabs-container">
        <SubTabs activeSubTab={activeSubTab} handleSubTabChange={handleSubTabChange} historySummary={historySummary} patientDocuments={patientDocuments} topSpacing={true} />
      </div>
      {audioInputDevices.length > 1 && !isStreaming && !isProcessing && (
        <>
          {isMicModalOpen && (
            <div className="dropdown-container">
              <div className="modal" onClick={() => setIsMicModalOpen(false)}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                  <label htmlFor="audio-input-select">Select Microphone:</label>
                  <select
                    id="audio-input-select"
                    value={selectedDeviceId}
                    onChange={(e) => handleMicChange(e.target.value, setSelectedDeviceId, updateUserSettings, audioInputDevices, userId)}>
                    {audioInputDevices.map((device) => {
                      let shortLabel = device.label || `Microphone ${device.deviceId}`;
                      if (device.label) {
                        const match = device.label.match(/\(([^)]+)\)/);
                        if (match) {
                          shortLabel = match[1].replace(/^[\d\s\W]+/, "");
                        }
                      }
                      return (
                        <option key={device.deviceId} value={device.deviceId}>
                          {shortLabel}
                        </option>
                      );
                    })}
                  </select>
                  <button className="button-action" onClick={() => setIsMicModalOpen(false)}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {isModalOpen && (
        <div className="modal" onClick={() => setIsModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-title">{recordingConfirmationMessage}</div>
            <div className="modal-button-container">
              <button className="button-delete" onClick={() => setIsModalOpen(false)}>
                Cancel
              </button>
              <button
                className="button-continue"
                onClick={() => {
                  setIsModalOpen(false);
                  handleStartStreaming(
                    selectedDeviceId,
                    patientId,
                    setIsStreaming,
                    socketRef,
                    clientId,
                    mediaRecorderRef,
                    audioStreamRef,
                    setVolumeLevel,
                    setUploadStartTime,
                    setIsProcessing,
                    setProcessingFail,
                    setBeginProcessing,
                    recordingTime,
                    patientDocuments,
                    selectedDocumentTypeId,
                    historyFields,
                    attemptUpdateData,
                    saveData,
                    isProcessing,
                    selectedDeviceId,
                    beginProcessing,
                    cancelProcessing,
                    setCurrentStatus,
                    patientData
                  );
                }}>
                Agree and Begin Recording
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewSession;
