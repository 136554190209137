import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './utils/authContext';
import logo from './assets/juniper-logo.png';
import { companyName, versionNum } from './globals';
import { useStreaming } from './contexts/StreamingContext';
import { useDocumentSections } from './contexts/DocumentSectionsContext';

import './css/Sidebar.css';
import { use, useEffect } from 'react';

const Sidebar = () => {
    const { currentUser, signOut } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { isStreaming } = useStreaming();
    const documentSectionsContext = useDocumentSections();
    const documentSections = documentSectionsContext ? documentSectionsContext.documentSections : null;

    useEffect(() => {
        if (documentSections) {
            console.log('Document Sections:', documentSections);
        }
    }, [documentSections]);

    const handleLogout = async () => {
        try {
            await signOut();
            navigate('/');
        } catch (error) {
            alert(error.message);
        }
    };

    const handleNavigation = (event, path) => {
        if (isStreaming) {
            const confirmLeave = window.confirm(
                "You have an ongoing recording. Are you sure you want to leave?"
            );
            if (!confirmLeave) {
                event.preventDefault();
                return;
            }
        }
        navigate(path);
    };

    const isActive = (path) => location.pathname === path;

    return (
        <div className="sidebar">

            <Link to="/dashboard">
                <img src={logo} alt="Logo" className="logo-sidebar" />
            </Link>

            <div className="user-info">Welcome:
                <p>{currentUser ? currentUser.email : 'Guest'}</p>
            </div>
            <ul>
                <li className={isActive('/dashboard') ? 'active' : ''}>
                    <Link to="/dashboard" onClick={(event) => handleNavigation(event, '/dashboard')}>Today</Link>
                </li>
                <li className={isActive('/patients') ? 'active' : ''}>
                    <Link to="/patients" onClick={(event) => handleNavigation(event, '/patients')}>Patients</Link>
                </li>
                <li className={isActive('/settings') ? 'active' : ''}>
                    <Link to="/settings" onClick={(event) => handleNavigation(event, '/settings')}>Settings</Link>
                </li>
            </ul>

            <button className="logout-button" onClick={handleLogout}>Sign Out</button>
            <div className="sidebar-footer">
                &copy; {new Date().getFullYear()} {companyName} v{versionNum}.<br />All rights reserved.
            </div>
        </div>
    );
};

export default Sidebar;