// utils/webSocket.js

import { ServerURI } from '../globals';
import { io } from 'socket.io-client';

export const initializeWebSocket = (setWsMessages, setClientId, endpoint = '') => {
    const socket = io(`${ServerURI}${endpoint}`);

    socket.on('connect', () => {
        setWsMessages(prevMessages => [...prevMessages, 'Socket.io connection opened']);
    });

    socket.on('clientId', (message) => {
        setClientId(message.clientId);
    });

    socket.on('message', (data) => {
        setWsMessages(prevMessages => [...prevMessages, data]);
    });

    socket.on('error', (error) => {
        console.error('Socket.io error:', error);
        setWsMessages(prevMessages => [...prevMessages, 'Socket.io error']);
    });

    socket.on('disconnect', () => {
        setWsMessages(prevMessages => [...prevMessages, 'Socket.io connection closed']);
    });

    const sendMessage = (message) => {
        socket.emit('message', message);
    };

    return { socket, sendMessage };
};
