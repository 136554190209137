import React, { useState, useEffect } from 'react';
import { auth, db } from './utils/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import logo from './assets/juniper-logo.png';
import { companyName, firebaseErrorMessages } from './globals';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [inviteCode, setInviteCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [companies, setCompanies] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("/companiesList");
                const data = await response.json();
                setCompanies(data.companies);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
            }
        };
        fetchData();
    }, []);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^\+?(\d{1,3})?[-.\s]?(\(?\d{3}\)?)[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
        return phoneRegex.test(phoneNumber);
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+]{8,}$/;
        return passwordRegex.test(password);
    };

    const validateInviteCode = (code) => {
        if (!code) return false;
        return Array.isArray(companies) && companies.some(company => company.code === code);
    };

    const handleSignUp = async () => {
        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        if (!validatePhoneNumber(phoneNumber)) {
            setErrorMessage('Please enter a valid phone number.');
            return;
        }

        if (!validatePassword(password)) {
            setErrorMessage('Password must be at least 8 characters long and include uppercase and lowercase letters, and a number.');
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage('Sorry, your passwords do not match. Please try again.');
            return;
        }

        if (!validateInviteCode(inviteCode)) {
            setErrorMessage('Sorry, that invite code is not valid, please try again.');
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(db, "users", user.uid), {
                email: user.email,
                phoneNumber: phoneNumber,
                inviteCode: inviteCode,
                createdAt: new Date(),
                patientIds: []
            });

            navigate('/dashboard');
        } catch (error) {
            const friendlyMessage = firebaseErrorMessages[error.code] || error.message;
            setErrorMessage(friendlyMessage);
        }
    };

      return (
        <div className="login-container">
            {companies.length === 0 ? (
                <p>Loading...</p>
            ) : (
                <>
                    <img src={logo} alt="Logo" className="logo-login" />
                    <h3 className="title">Enter your information below to begin using Juniper</h3>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        className="main-input"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        className="main-input"
                    />
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                        className="main-input"
                    />
                    <hr></hr>
                    <input
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Phone Number"
                        className="main-input"
                    />
                    <input
                        type="text"
                        value={inviteCode}
                        onChange={(e) => setInviteCode(e.target.value)}
                        placeholder="Enter your Invite Code"
                        className="main-input"
                    />
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                    <button onClick={handleSignUp} className="center-button primary">Sign Up</button>
                    <footer className="footer">
                        <p>&copy; {new Date().getFullYear()} {companyName}. All rights reserved.</p>
                    </footer>
                </>
            )}
        </div>
    );
};

export default Signup;