export const volumeMeter = (stream, setVolumeLevel) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const analyser = audioContext.createAnalyser();
  const source = audioContext.createMediaStreamSource(stream);
  source.connect(analyser);
  analyser.fftSize = 256;
  const dataArray = new Uint8Array(analyser.frequencyBinCount);

  const updateVolume = () => {
    analyser.getByteFrequencyData(dataArray);
    const volume = dataArray.reduce((a, b) => a + b) / dataArray.length;
    const volumeLevel = Math.min(10, Math.round(volume / 10));

    setVolumeLevel(volumeLevel);

    requestAnimationFrame(updateVolume);
  };
  updateVolume();
};

export const convertFloat32ToLinear16 = (input) => {
  const output = new Int16Array(input.length);
  for (let i = 0; i < input.length; i++) {
    const s = Math.max(-1, Math.min(1, input[i]));
    output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
  }
  return output;
};
export const fetchAudioInputDevices = async (
  setAudioInputDevices,
  setSelectedDeviceId
) => {
  let stream;
  try {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter(
      (device) => device.kind === "audioinput"
    );
    setAudioInputDevices(audioDevices);

    if (audioDevices.length > 0) {
      setSelectedDeviceId(audioDevices[0].deviceId);
    } else {
      console.warn("No audio input devices found.");
    }
  } catch (error) {
    console.error("Error fetching audio input devices:", error);
  } finally {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
  }
};

export const handleMicChange = async (
  deviceId,
  setSelectedDeviceId,
  updateUserSettings,
  audioInputDevices,
  userId
) => {
  let stream;
  setSelectedDeviceId(deviceId);
  try {
    stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: deviceId } } });

    const devices = audioInputDevices.map((device) => ({
      deviceId: device.deviceId,
      label: device.label,
    }));
    await updateUserSettings(userId, {
      microphone: deviceId,
      audioDevices: devices,
    });
  } catch (error) {
    console.error("Error updating user settings:", error);
  } finally {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
  }
};