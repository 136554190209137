import React, { useEffect, useState } from "react";
import { getData, getDocuments, updateData } from "./utils/firestoreService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./utils/authContext";
import MenuComponent from "./utils/menuComponent";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import PushPinIcon from "@mui/icons-material/PushPin";
import Pagination from "@mui/material/Pagination";
import AddEditPatient from "./utils/AddEditPatient";
import { mostRecentHistory } from "./utils/historyProcessing";

const Dashboard = () => {
  const [patients, setPatients] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  const navigate = useNavigate();
  const [isEditPatientOpen, setEditPatientOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const documentsPerPage = 50;
  const tagWeight = 0.75;

  const fetchPatients = async () => {
    try {
      if (!currentUser) return;

      const userDoc = await getData(`/users/${userId}`);

      const patientIds = userDoc.patientIds || [];

      if (patientIds.length === 0) {
        setPatients([]);
        return;
      }

      const patientPromises = patientIds.map(async (id) => {
        const patient = await getData(`/patients/${id}`);
        if (patient) {
          try {
            const documents = await getDocuments(`patients/${id}/documents`);
            const mostRecent = mostRecentHistory(documents);
            return { ...patient, document: mostRecent };
          } catch (docError) {
            console.error(`Error fetching documents for patient ${id}:`, docError);
            return { ...patient, document: [] };
          }
        }
        return null;
      });

      const patientsList = await Promise.all(patientPromises);

      const activePatients = patientsList.filter((patient) => patient && patient.isArchived !== true);
      setPatients(activePatients);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  useEffect(() => {
    fetchPatients();
  }, [userId]);

  const handlePatientClick = (patient) => {
    navigate("/Patient", { state: { patientId: patient.id } });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const capitalizePronoun = (pronoun) => {
    return pronoun
      .split("/")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("/");
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handlePinClick = async (patient) => {
    try {
      await updateData('patients', patient.id, { pin: !patient.pin });
      setPatients((prevPatients) =>
        prevPatients.map((p) =>
          p.id === patient.id ? { ...p, pin: !p.pin } : p
        )
      );
    } catch (error) {
      console.error("Error toggling pin status:", error);
    }
  };

  const filteredPatients = patients
    .filter((patient) => `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(searchQuery.toLowerCase()) && !patient.isArchived)
    .sort((a, b) => a.lastName.localeCompare(b.lastName));

  const indexOfLastPatient = currentPage * documentsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - documentsPerPage;
  const currentPatients = filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  return (
    <div className="page-container">
      <div className="header-container">
        <div className="page-title">My Patients</div>
        <div className="header-search">
          <div className="home-header-search-wrapper">
            <SearchIcon className="header-search-icon" />
            <input type="text" placeholder="Search Patients" value={searchQuery} onChange={handleSearchChange} className="header-search-input" />
          </div>
        </div>
        <div title="Add Patient" className="header-button" onClick={() => setEditPatientOpen(true)}>
          <PersonAddIcon className="header-icon" /> Add Patient
        </div>
        <AddEditPatient setPatients={setPatients} isEditPatientOpen={isEditPatientOpen} setEditPatientOpen={setEditPatientOpen} />
      </div>
      <div className="standard-container">
        <table className="standard-table thinner-rules">
          <thead>
            <tr>
              <th className="column-3"></th>
              <th className="column-20"></th>
              <th className="column-50"></th>
              <th className="column-5"></th>
            </tr>
          </thead>
          <tbody>
            {currentPatients.length === 0 ? (
              <tr>
                <td colSpan="4" style={{ textAlign: "center" }}>
                  No patients to display. Add one above...
                </td>
              </tr>
            ) : (
              currentPatients.map((patient) => (
                <tr key={patient.id} className="patient-list">
                  <td>
                    <PushPinIcon
                      style={{
                        fontSize: 13,
                        color: patient.pin ? "#0c1e5c" : "#d6d3f5",
                        margin: "4px",
                        marginTop: "7px",
                        cursor: "pointer",
                        transform: "rotate(-13deg)", 
                      }}
                      onClick={() => handlePinClick(patient)}
                    />
                  </td>
                  <td onClick={() => handlePatientClick(patient)} style={{ cursor: "pointer" }}>
                    <span className="table-primary-text">
                      {patient.lastName}, {patient.firstName}
                    </span>
                  </td>
                  <td>
                    {patient.pronoun && <span className="patient-details-small"> {capitalizePronoun(patient.pronoun)}, </span>}
                    {patient.dateOfBirth && (
                      <span className="patient-details-small">
                        Aged {calculateAge(patient.dateOfBirth)}, DOB: {patient.dateOfBirth}
                      </span>
                    )}
                    {patient.document && (
                      <>
                        {Array.isArray(patient.document.tags) &&
                          patient.document.tags.length > 0 &&
                          patient.document.tags
                            .filter((tag) => tag.weight > tagWeight)
                            .map((tag, index) => (
                              <span key={index} className="tag-box">
                                  {tag.tag.charAt(0).toUpperCase() + tag.tag.slice(1)}
                              </span>
                            ))}
                        <br />
                        <div className="summary-overview">{patient.document.documentDate}: 
                          {patient.document.history_summary.length > 256 ? `  ${patient.document.history_summary.substring(0, 144)}...` : patient.document.history_summary}
                        </div>
                      </>
                    )}
                  </td>
                  <td>
                    <MenuComponent patientId={patient.id} />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="pagination-controls">
          <Pagination
            count={Math.ceil(filteredPatients.length / documentsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            className="pagination"
            color="primary"
            variant="outlined"
            shape="rounded"
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
