import React, { useState } from 'react';
import { auth } from './utils/firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import logo from './assets/juniper-logo.png';
import { companyName, firebaseErrorMessages } from './globals';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleLogin = async () => {
        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }
    
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            navigate('/dashboard');
        } catch (error) {
              const friendlyMessage = firebaseErrorMessages[error.code] || error.message;
              setErrorMessage(friendlyMessage);
        }
    };

    const handlePasswordRecovery = async () => {
        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setErrorMessage('Password reset email sent!');
        } catch (error) {
              const friendlyMessage = firebaseErrorMessages[error.code] || error.message;
              setErrorMessage(friendlyMessage);
        }
    };

    return (
        <div className="login-container">
            <img src={logo} alt="Logo" className="logo-login" />
            <h2 className="title-login">Login</h2>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="main-input"
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="main-input"
            />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button onClick={handleLogin} className="center-button primary">Login</button>
            <Link to="/signup" className="center-button secondary">Sign Up</Link>
            <Link to="#" onClick={handlePasswordRecovery} className="login-link">Recover Password</Link>
            <footer className="footer">
                <p>&copy; {new Date().getFullYear()} {companyName}. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default Login;