import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { formatListItem, generateSectionExport } from "../utils/exportUtils";
import { processHistorySection } from "../utils/historyProcessing";
import { useStreaming } from "../contexts/StreamingContext";

const SubTabs = ({
  activeSubTab,
  handleSubTabChange,
  historySummary,
  patientDocuments,
  topSpacing
}) => {
  const [sections, setSections] = useState({});
  const [combinedData, setCombinedData] = useState(null);
  const { isStreaming } = useStreaming();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/sectionList");
        const data = await response.json();
        setSections(data.mainSections);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("useEffect for processing section data called");

    const processSectionData = (sectionKey) => {
      const key = sections[sectionKey].subData;
      const historyData = Object.keys(patientDocuments).reduce((acc, curr) => {
        if (patientDocuments[curr][key]) {
          acc.push(...patientDocuments[curr][key]);
        }
        return acc;
      }, []);
      const data = processHistorySection(
        sections[sectionKey],
        historySummary[key],
        historyData
      );
      setCombinedData({ [key]: data });
    };

    let debounceTimeout;
    if (isStreaming) {
      debounceTimeout = setTimeout(() => {
        if (activeSubTab === 3 && sections.medications) {
          processSectionData("medications");
        } else if (activeSubTab === 4 && sections.substanceUse) {
          processSectionData("substanceUse");
        } else if (activeSubTab === 5 && sections.familyHistory) {
          processSectionData("familyHistory");
        } else if (activeSubTab === 7 && sections.allergies) {
          processSectionData("allergies");
        }
      }, 500);
    } else {
      if (activeSubTab === 3 && sections.medications) {
        processSectionData("medications");
      } else if (activeSubTab === 4 && sections.substanceUse) {
        processSectionData("substanceUse");
      } else if (activeSubTab === 5 && sections.familyHistory) {
        processSectionData("familyHistory");
      } else if (activeSubTab === 7 && sections.allergies) {
        processSectionData("allergies");
      }
    }

    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
  }, [activeSubTab, sections, historySummary, patientDocuments, isStreaming]);

  const renderDefaultMessage = () => (
    <div className="history-summary-section">
      <div className="user-message">
        No Histories available yet... Check back after running a Progress Note.
      </div>
    </div>
  );

  const renderList = (summary, sectionKey) => {
    if (!summary || Object.keys(summary).length === 0) {
      return renderDefaultMessage();
    }

    const section = sections[sectionKey];

    if (!section || !section.fields) {
      return renderDefaultMessage();
    }

    return (
      <div className="history-summary-section">
        <ul className="history-summary-list">
          {section.fields.map((field) => (
            <div key={field}>
              {formatListItem(
                field,
                summary[field] || "No historical information available"
              )}
            </div>
          ))}
        </ul>
      </div>
    );
  };

  const renderSection = (sectionKey, dataKey) => {
    return (
      <div className="history-summary-section">
        <div
          className="history-summary-list"
          dangerouslySetInnerHTML={{
            __html:
              combinedData && sections[sectionKey]
                ? generateSectionExport(
                    sections[sectionKey],
                    combinedData,
                    patientDocuments
                  )?.[dataKey]?.data || "No data available"
                : "Loading data...",
          }}
        />
      </div>
    );
  };

  return (
    <div className="patient-history-container">
      <div className="patient-subtabs-container">
        <Tabs
          value={activeSubTab}
          onChange={handleSubTabChange}
          className="patient-sub-tabs"
          scrollButtons="auto"
          sx={{ height: "32px" }}
        >
          <Tab label="Social History" className="patient-sub-tab" wrapped />
          <Tab label="Trauma History" className="patient-sub-tab" wrapped />
          <Tab
            label="Past Psychiatric History"
            className="patient-sub-tab"
            wrapped
          />
          <Tab label="Medication History" className="patient-sub-tab" wrapped />
          <Tab
            label="Substance Use"
            className="patient-sub-tab"
            wrapped
          />
          <Tab label="Family History" className="patient-sub-tab" wrapped />
          <Tab label="Medical History" className="patient-sub-tab" wrapped />
          <Tab label="Allergies History" className="patient-sub-tab" wrapped />
        </Tabs>
      </div>
      {Object.keys(sections).length > 0 && (
        <div className={topSpacing ? "session-history-spacing" : ""}>
          {activeSubTab === 0 &&
            historySummary.social_history_summary &&
            renderList(historySummary.social_history_summary, "socialHistory")}
  
          {activeSubTab === 1 &&
            historySummary.trauma_history_summary &&
            renderList(historySummary.trauma_history_summary, "traumaHistory")}
  
          {activeSubTab === 2 && (
            <>
              {historySummary.psychiatric_history_summary &&
                renderList(
                  historySummary.psychiatric_history_summary,
                  "psychiatricHistory"
                )}
            </>
          )}
  
          {activeSubTab === 3 &&
            sections.medications &&
            renderSection("medications", "medications")}
  
          {activeSubTab === 4 &&
            sections.substanceUse &&
            renderSection("substanceUse", "substance_use")}
  
          {activeSubTab === 5 &&
            sections.familyHistory &&
            renderSection("familyHistory", "family_history")}
  
          {activeSubTab === 6 && historySummary.medical_history_summary && (
            <div>
              {renderList(
                historySummary.medical_history_summary,
                "medicalHistory"
              )}
  
              {activeSubTab === 7 &&
              sections.allergies &&
               renderSection("allergies", "allergies", false)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubTabs;