// utils/fetchDocumentTypes.js
export const fetchDocumentTypes = async (setDocumentTypes, setSelectedDocumentType, setSelectedDocumentTypeId, setSelectedCategoryId, setEta, Id = 0) => {
    try {
        const response = await fetch('/documentTypes');
        if (response.ok) {
            const data = await response.json();
            if (Array.isArray(data) && data.length > 0) {
                setDocumentTypes(data);
                setSelectedDocumentType(data[Id].name);
                setSelectedDocumentTypeId(data[Id].id);
                setSelectedCategoryId(data[Id].category);
                setEta(data[Id].eta);
            } else {
                console.error('Document types data is not an array or is empty:', data);
            }
        } else {
            console.error('Failed to fetch document types');
        }
    } catch (error) {
        console.error('Error fetching document types:', error);
    }
};