import React, { createContext, useContext, useState } from 'react';

const StreamingContext = createContext();

export const StreamingProvider = ({ children }) => {
    const [isStreaming, setIsStreaming] = useState(false);
    return (
        <StreamingContext.Provider value={{ isStreaming, setIsStreaming }}>
            {children}
        </StreamingContext.Provider>
    );
};

export const useStreaming = () => useContext(StreamingContext);