import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  deleteData,
  getDocument,
  getDocuments,
} from "./utils/firestoreService";
import MenuComponent from "./utils/menuComponent";
import { Pagination, Tabs, Tab } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchIcon from "@mui/icons-material/Search";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MicIcon from "@mui/icons-material/Mic";
import UpdateIcon from "@mui/icons-material/Update";
import SubTabs from "./tabs/subtabs";
import { mostRecentHistory } from "./utils/historyProcessing";

let patientData = null;

const Patient = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { patientId } = location.state || {};
  const [personName, setPersonName] = useState("");
  const [patientDocuments, setPatientDocuments] = useState([]);
  const [historySummary, setHistorySummary] = useState(null); //most recent history summaries for goals and tabs
  const [searchQuery, setSearchQuery] = useState("");
  const hasFetchedDataRef = useRef(false);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteDocId, setDeleteDocId] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [documentCategoryCount, setDocumentCategoryCount] = useState(0);
  const documentsPerPage = 20;
  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const tagWeight = 0.75;

  useEffect(() => {
    if (hasFetchedDataRef.current) return;

    if (!patientId) {
      navigate("/patient", { replace: true });
      return;
    }

    const fetchPatientData = async () => {
      try {
        hasFetchedDataRef.current = true;

        patientData = await getDocument(`patients`, patientId);

        if (patientData) {
          const formattedPersonName = `${patientData.firstName} ${patientData.lastName}`;
          setPersonName(formattedPersonName);

          let documents = await getDocuments(`patients/${patientId}/documents`);

          documents = documents.sort(
            (a, b) => b.modifiedAt.seconds - a.modifiedAt.seconds
          );

          setPatientDocuments(documents);

          const documentCategoryCount = documents.filter(
            (doc) => doc.category === 1
          ).length;
          setDocumentCategoryCount(documentCategoryCount);

          if (documents.length > 0) {
            const mostRecent = mostRecentHistory(documents);
            if (mostRecent) {
              setHistorySummary(mostRecent);
            }
          }
        } else {
          console.error("No such patient!");
        }
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    };

    fetchPatientData();
  }, [patientId, navigate]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSubTabChange = (event, newValue) => {
    setActiveSubTab(newValue);
  };

  const handleDeleteDocument = async (docId) => {
    await deleteData(`patients/${patientId}/documents`, docId);
    setPatientDocuments((prevDocs) =>
      prevDocs.filter((doc) => doc.id !== docId)
    );
  };

  const handleDocumentClick = (docId) => {
    navigate("/document", { state: { patientId, docId } });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const searchInObject = (obj, searchTerm) => {
    if (!obj) return false;
    return Object.values(obj).some((value) => {
      if (typeof value === "string") {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      if (typeof value === "object") {
        return searchInObject(value, searchTerm);
      }
      return false;
    });
  };

  const filteredDocuments = patientDocuments.filter((doc) => {
    const searchTerm = searchQuery.toLowerCase();
    return (
      doc.title?.toLowerCase().includes(searchTerm) ||
      doc.document_summary?.toLowerCase().includes(searchTerm) ||
      doc.documentDate?.toLowerCase().includes(searchTerm) ||
      doc.interval_history?.toLowerCase().includes(searchTerm) ||
      doc.history_goals?.toLowerCase().includes(searchTerm) ||
      doc.history_summary?.toLowerCase().includes(searchTerm) ||
      doc.cleaned_text?.toLowerCase().includes(searchTerm) ||
      searchInObject(doc.sections, searchTerm)
    );
  });

  const currentDocuments = filteredDocuments.slice(
    indexOfFirstDocument,
    indexOfLastDocument
  );
  const totalPages = Math.ceil(filteredDocuments.length / documentsPerPage);

  return (
    <div className="page-container">
      <div className="header-container">
        <button onClick={handleBack}>&larr;</button>
        {personName && (
          <div className="page-title">Patient Records for {personName}</div>
        )}
        {personName && (
          <>
            {documentCategoryCount > 30 && (
              <div title="Update History" className="header-button">
                <UpdateIcon className="header-icon" /> Update History
              </div>
            )}
            <MenuComponent patientId={patientId} />
          </>
        )}
      </div>
      <div className="patient-content-container">
        <div className="patient-tabs-container">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            className="patient-tabs"
            scrollButtons="auto"
          >
            <Tab label="Overview" className="patient-tab" wrapped />
            <Tab label="Documents" className="patient-tab" wrapped />
            <Tab label="History" className="patient-tab" wrapped />
          </Tabs>
        </div>

        {activeTab === 0 && (
          <div className="patient-details-container">
            {patientData && (
              <div className="patient-details">
                <p>
                  <strong>Date of Birth:</strong> {patientData.dateOfBirth}
                </p>
                <p>
                  <strong>Gender:</strong> {patientData.gender}
                </p>
                <p>
                  <strong>Goals:</strong>
                </p>
                {historySummary && historySummary.history_goals ? (
                  <p>{historySummary.history_goals}</p>
                ) : (
                  <p>Goals are not yet available.</p>
                )}
                <br />
                <p>
                  <strong>History Summary:</strong>
                </p>
                {historySummary && historySummary.history_summary ? (
                  <div>
                    <p>{historySummary.history_summary}</p>
                  </div>
                ) : (
                  <p>History not yet available... please run a new Progress Note.</p>
                )}
              </div>
            )}
          </div>
        )}

        {activeTab === 1 && (
          <div className="documents-list-container">
            <div className="patient-header-container">
              <div className="header-search">
                <div className="header-search-wrapper">
                  <SearchIcon className="header-search-icon" />
                  <input
                    type="text"
                    placeholder=""
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="header-search-input"
                  />
                </div>
              </div>
            </div>
      
            <table className="standard-table documents-list-table">

              <thead>
                <tr>
                  <th className="column-3"></th>
                  <th className="column-70"></th>
                </tr>
              </thead>
              <tbody>
                {currentDocuments.length === 0 ? (
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>
                      No documents found...
                    </td>
                  </tr>
                ) : (
                  currentDocuments.map((doc, index) => (
                    <tr key={index}>
                      <td>
                        <button
                          className="table-icon-lighter"
                          onClick={() => handleDocumentClick(doc.id)}
                          style={{
                            border: "none",
                            background: "none",
                            padding: 0,
                            cursor: "pointer",
                          }}
                        >
                          {doc.typeId === 1 || doc.typeId === 2 ? (
                            <MicIcon className="table-icon" />
                          ) : (
                            <AssignmentIcon className="table-icon" />
                          )}
                        </button>
                      </td>
                      <td>
                        <h4 className="document-list-title">
                          <strong
                            onClick={() => handleDocumentClick(doc.id)}
                            style={{ cursor: "pointer" }}
                          >
                            {doc.title}
                          </strong>
                        </h4>
                        <div className="summary-small">
                          {doc.documentDate}
                          {Array.isArray(doc.tags) && doc.tags.length > 0 && (
                            doc.tags
                              .filter(tag => tag.weight > tagWeight)
                              .map((tag, index) => (
                                <span key={index} className="tag-box">{tag.tag}</span>
                              ))
                          )}
                          <DeleteOutlineIcon
                            className="table-icon-lighter"
                            onClick={() => {
                              setDeleteConfirmOpen(true);
                              setDeleteDocId(doc.id);
                            }}
                            style={{
                              marginLeft: "6px",
                              cursor: "pointer",
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        </div>
                        <div className="summary-small">
                          {doc.short_summary
                            ? doc.short_summary.length > 480
                              ? doc.short_summary.substring(0, 480) + "..."
                              : doc.short_summary
                            : ""}
                        </div>
                      </td>
                      {isDeleteConfirmOpen && deleteDocId === doc.id && (
                        <div
                          className="modal"
                          onClick={() => setDeleteConfirmOpen(false)}
                        >
                          <div
                            className="modal-content"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="modal-title">
                              Are you sure you want to delete this document? There's no undo!
                            </div>
                            <div className="modal-button-container">
                              <button
                                className="button-cancel"
                                onClick={() => setDeleteConfirmOpen(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className="button-delete"
                                onClick={() => {
                                  handleDeleteDocument(doc.id);
                                  setDeleteConfirmOpen(false);
                                }}
                              >
                                Yes, Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="pagination-controls">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                color="primary"
                variant="outlined"
                         shape="rounded"
                size="small"
              />
            </div>
          </div>
        )}

        {activeTab === 2 && (
          historySummary ? (
            <SubTabs
              activeSubTab={activeSubTab}
              handleSubTabChange={handleSubTabChange}
              historySummary={historySummary}
              patientDocuments={patientDocuments}
            />
          ) : (
            <div className="history-summary-section">History not yet available... please run a new Progress Note.</div>
          )
        )}
      </div>
    </div>
  );
};

export default Patient;
