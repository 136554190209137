import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { CssBaseline, Box } from '@mui/material';
import { AuthProvider, useAuth } from './utils/authContext';
import { StreamingProvider } from './contexts/StreamingContext';

import './css/Global.css';

//main pages
import Login from './Login';
import Signup from './Signup';
import Dashboard from './Dashboard';
import Patients from './Patients';
import NewSession from './NewSession';
import Document from './Document';
import Patient from './Patient';
import NewDocument from './NewDocument';
import NewText from './NewText';
import Settings from './Settings';
import Sidebar from './Sidebar';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/" />;
};

const AppLayout = ({ children }) => {
  const location = useLocation();
  const hideSidebar = location.pathname === '/' || location.pathname === '/signup';

  return (
    <Box sx={{ display: 'flex' }}>
      <StreamingProvider>
        {!hideSidebar && <Sidebar />}
        <Box component="main" sx={{ flexGrow: 1, ml: hideSidebar ? 0 : '200px', p: 3 }}>
          {children}
        </Box>
      </StreamingProvider>
    </Box>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <CssBaseline />
        <AppLayout>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/newSession"
              element={
                <PrivateRoute>
                  <NewSession />
                </PrivateRoute>
              }
            />
            <Route
              path="/patient"
              element={
                <PrivateRoute>
                  <Patient />
                </PrivateRoute>
              }
            />
                        <Route
              path="/patients"
              element={
                <PrivateRoute>
                  <Patients />
                </PrivateRoute>
              }
            />
            <Route
              path="/newDocument"
              element={
                <PrivateRoute>
                  <NewDocument />
                </PrivateRoute>
              }
            />
            <Route
              path="/newText"
              element={
                <PrivateRoute>
                  <NewText />
                </PrivateRoute>
              }
            />
            <Route
              path="/document"
              element={
                <PrivateRoute>
                  <Document />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
          </Routes>
        </AppLayout>
      </Router>
    </AuthProvider>
  );
};

export default App;