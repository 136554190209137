import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { updateData } from "../utils/firestoreService";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import debounce from "lodash/debounce";
import { mostRecentDocument } from "../utils/historyProcessing";

const EditableTextSection = ({ section, sectionKey, documentData, dataHistory, copyToClipboard, ContentCopyIcon, patientId, docId, defaultOpen = true }) => {
  const quillRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isHistory, setIsHistory] = useState(false);
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isEditing, setIsEditing] = useState(false);
  const [lastSavedContent, setLastSavedContent] = useState("");

  useEffect(() => {
    if (section.dataField !== undefined && documentData && documentData[section.dataField]) {
      setEditorContent(documentData[section.dataField]);
    } else if (section.displayHistory !== undefined && dataHistory && dataHistory.length > 0) {
      const mostRecent = mostRecentDocument(dataHistory, section.dataField);
      if (mostRecent && mostRecent[section.dataField]) {
        setEditorContent(mostRecent[section.dataField]);
        setIsHistory(true);
      }
    } else if (documentData && documentData.defaultData) {
      setEditorContent(documentData.defaultData);
    }
  }, [section.displayHistory, section.dataField, dataHistory, documentData]);

  const handleSave = async (content) => {
    setIsSaving(true);
    try {
      await updateData(`patients/${patientId}/documents`, docId, { [section.dataField]: content });
      setLastSavedContent(content);
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  const debouncedSave = debounce((content) => handleSave(content), 1000);

  const handleEditorChange = (content) => {
    setEditorContent(content);
    setIsEditing(true);
    setIsHistory(false);
    debouncedSave(content);
  };

  const handleBlur = () => {
    handleSave(editorContent);
    setIsEditing(false);
    setIsHistory(false);
  };

  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (isEditing && !isSaving && editorContent !== lastSavedContent) {
        handleSave(editorContent);
      }
    }, 15000);

    return () => clearInterval(autoSaveInterval);
  }, [editorContent, isEditing, isSaving, lastSavedContent]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const getToolbarOptions = (type) => {
    switch (type) {
      case "minimal":
        return [
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
        ];
      case "none":
        return false;
      case "full":
      default:
        return [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
        ];
    }
  };

  const toolbarOptions = getToolbarOptions(section.toolbarType);

  return (
    <div className="standard-container" key={sectionKey}>
      <div className="edit-header-container">
        <div className="edit-header-left" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          <h3 className="edit-header">
            <span>{isOpen ? <ArrowDropUpIcon className="accordion-icon" /> : <ArrowDropDownIcon className="accordion-icon" />}</span>
            {section.title}
          </h3>
        </div>
        <div className="edit-header-right">
          <span title="Copy Section Text" onClick={() => copyToClipboard(editorContent)} className="copy-icon">
            {isOpen ? <ContentCopyIcon style={{ fontSize: "16px" }} /> : ""}
          </span>
        </div>
      </div>
      {isOpen && (
        <div className="text-editor-container">
          <div className={`text-editor-box ${isHistory ? 'text-history' : ''}`} style={{ resize: "vertical", overflow: "auto" }}>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={editorContent}
              onChange={handleEditorChange}
              onBlur={handleBlur}
              modules={{ toolbar: toolbarOptions }}
              style={{ height: "96%" }}
            />
          </div>
          <div className="editor-message">{section.message}</div>
        </div>
      )}
    </div>
  );
};

export default EditableTextSection;
