import { addData } from "./firestoreService";
import { volumeMeter, convertFloat32ToLinear16 } from "./audioUtils";
import { recordingTimeLimit } from "../globals";

export const handleStartStreaming = async (
  deviceId,
  patientId,
  setIsStreaming,
  socketRef,
  clientId,
  mediaRecorderRef,
  audioStreamRef,
  setVolumeLevel,
  setUploadStartTime,
  setIsProcessing,
  setProcessingFail,
  setBeginProcessing,
  recordingTime,
  patientDocuments,
  selectedDocumentTypeId,
  historyFields,
  attemptUpdateData,
  saveData,
  isProcessing,
  selectedDeviceId,
  beginProcessing,
  cancelProcessing,
  setCurrentStatus,
  patientData
) => {
  console.log("handleStartStreaming function called");

  const newDocData = {
    title: `New Session`,
    description: "Streaming Audio.",
    text: "",
    type: "session",
    createdAt: new Date(),
    modifiedAt: new Date(),
    documentDate: new Date().toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    }),
    startTime: new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }),
    endTime: new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }),
  };

  let documentId;

  try {
    documentId = await addData(`patients/${patientId}/documents`, newDocData);
    setIsStreaming(true);
  } catch (error) {
    console.error("Error creating new document:", error);
    return;
  }

  if (!deviceId) {
    console.error("No audio input devices found");
    return;
  }

  socketRef.current.emit("startStream", clientId);

  socketRef.current.on("disconnect", () => {
    console.log("Audio-stream Socket.io connection closed");
    setIsStreaming(false);
    setCurrentStatus("Streaming interrupted");
    if (recordingTime > recordingTimeLimit) {
      setBeginProcessing(true);
    }
  });

  socketRef.current.on("error", (error) => {
    console.error("Audio-stream Socket.io error:", error);
    reconnect();
  });

  const reconnect = () => {
    setTimeout(() => {
      if (!socketRef.current.connected) {
        console.log("Attempting to reconnect...");
        socketRef.current.connect();
      }
    }, 5000);
  };

  socketRef.current.on("action", async (data) => {
    try {
      if (typeof data === "string") {
        data = JSON.parse(data);
      }
      if (data.error) {
        console.error("Error:", data.error);
      } else {
        if (data.action === "save") {
          if (data.type === "progress") {
            const updatedDocData = {
              text: data.processedText,
              modifiedAt: new Date(),
              endTime: new Date().toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              }),
            };

            await attemptUpdateData(`patients/${patientId}/documents`, documentId, updatedDocData);
          } else if ((data.type === "final" && !isProcessing && !cancelProcessing) || beginProcessing) {
            try {
              let finalText = data.processedText;
              const startTime = Date.now();
              setUploadStartTime(startTime);
              setIsProcessing(true);

              const patientHistory = JSON.parse(JSON.stringify(patientDocuments));

              Object.keys(patientHistory).forEach((key) => {
                const document = patientHistory[key];
                Object.keys(document).forEach((field) => {
                  if (!historyFields.includes(field)) {
                    delete document[field];
                  }
                });
              });

              const response = await fetch("/processText", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  text: finalText,
                  clientId,
                  docTypeId: selectedDocumentTypeId,
                  patientData,
                  patientHistory,
                }),
              });

              if (!response.ok) {
                throw new Error(`Server error: ${response.status} ${response.statusText}`);
              }

              const dataResponse = await response.json();
              await saveData(patientId, documentId, finalText, dataResponse);
            } catch (error) {
              console.error("Error processing text:", error);
              setIsProcessing(false);
              setProcessingFail(true);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error parsing data:", error);
    }
  });

  socketRef.current.on("error", (error) => {
    console.error("Audio-stream Socket.io error:", error);
  });

  try {
    console.log(selectedDeviceId);
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: deviceId ? { exact: deviceId } : undefined,
        sampleRate: 16000,
      },
    });

    audioStreamRef.current = stream;

    const audioContext = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: 16000 });
    const source = audioContext.createMediaStreamSource(stream);
    const processor = audioContext.createScriptProcessor(4096, 1, 1);

    source.connect(processor);
    processor.connect(audioContext.destination);

    processor.onaudioprocess = (e) => {
      const inputBuffer = e.inputBuffer;
      const inputData = inputBuffer.getChannelData(0);
      const pcmData = convertFloat32ToLinear16(inputData);
      if (socketRef.current && socketRef.current.connected) {
        socketRef.current.emit("audio", pcmData);
      }
    };

    mediaRecorderRef.current = processor;
    volumeMeter(stream, setVolumeLevel);
  } catch (error) {
    console.error("Error accessing media devices:", error);
  }
};