// development and production values

const isLocalhost = window.location.hostname === 'localhost';
const protocol = window.location.protocol === 'https:' ? 'https:' : 'http:';
export const ServerURI = isLocalhost ? `${protocol}//localhost:8080` : `${protocol}//app.juniperhealthsystems.com/`;

// global variables used across Juniper
export const companyName = 'Juniper Health Systems, Inc';

export const versionNum = '010825.01';

export const historyFields = ['documentDate', 'createdAt', 'modifiedAt', 'document_summary', 'plan', 'after_visit_summary', 'medications', 'diagnostic_impressions_data', 'interval_history', 'substance_use', 'family_history', 'allergies', 'social_history', 'trauma_history', 'psychiatric_history','medical_history'];

export const historyDepth = 4; // future feature to determine how far back to look
 
export const firebaseErrorMessages = {
    'auth/invalid-credential': 'The information you entered is invalid. Please try again.',
    'auth/user-not-found': 'No user found with this email. Please check the email address and try again.',
    'auth/wrong-password': 'The password you entered is incorrect. Please try again.',
    'auth/email-already-in-use': 'This email is already in use. Please use a different email.',
    'auth/weak-password': 'The password is too weak. Please use a stronger password.',
    'auth/too-many-requests': 'Too many requests. Please try again later.',
};

export const recordingConfirmationMessage = `Please confirm that both you and the patient agree to this recording.`

export const recordingDebounceTime = 3000; // time between recording updates

export const recordingTimeLimit = 3599000; // 59 minutes and 59 seconds

// unknown if being used

export const keepAliveInterval = 10000; // 10 seconds

export const substanceMatchingNames = {
    nicotine: 'tobacco',
    tobacco: 'tobacco',
    cigarettes: 'tobacco',
};

export const medicationMatchingNames = {
    zoloft: 'sertraline'
}

export const noWords = ['no', 'none', 'n/a', 'not applicable'];

export const yesWords = ['yes', 'affirmative'];

export const patientInfoFields = ['name', 'date_of_birth', 'gender'];

export const mentalStatusFields = ["gait",
    "musculo_skeletal",
    "orientation",
    "attitude",
    "appearance",
    "eye_contact",
    "mood",
    "affect",
    "speech",
    "associations",
    "thought_process",
    "thought_content",
    "perceptions",
    "delusions",
    "attention_concentration",
    "fund_of_knowledge",
    "memory_short_term",
    "memory_long_term",
    "language",
    "abstraction",
    "associations",
    "insight",
    "judgment",
    "additional_comments"];

export const socialHistoryFields = [
    "where_born_and_raised",
    "members_of_childhood_household",
    "level_of_education_attained",
    "employment_history",
    "current_employment",
    "work_related_stress",
    "financial_status",
    "current_living_situation",
    "previous_living_situation",
    "marital_status",
    "children",
    "current_household",
    "household_dynamics",
    "trauma_history"
]

export const planHTML = `
<strong>PLAN –</strong><br><br>
1. <strong>[Symptom 1 – often mood and/or anxiety]</strong>: <br><br>
2. <strong>[Symptom 2]</strong>: <br><br>
3. <strong>[Symptom 3]</strong>: <br><br>
4. <strong>Somatic</strong>: <br><br>
5. <strong>Psychosocial</strong>: <br><br>
Will follow up at time X. <br><br>
This section contains default text that every note will have. Let me know the correct wording and formatting.`;

export const riskAssessment = {
    risk1: 'There is no imminent risk of harm to self or others and remains appropriate to proceed at the current level of care.',
    risk2: 'There is low risk of harm to self or others.',
    risk3: 'There is significant risk of harm to self or others requiring urgent evaluation and / or inpatient treatment.'
};

